<template>
  <b-modal id="modal-content" size="lg" hide-footer>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="InfoIcon" class="mr-05" />
        <span class="fs-18">Detail Konten</span>
      </div>
    </template>
    <div v-if="data" class="row mb-2">
      <div class="col-md-12">
        <p class="fs-16 font-weight-bolder text-justify">{{ data.title }}</p>
        <div class="d-flex">
          <span class="mr-2 fs-14 d-flex align-items-center">
            <feather-icon icon="GlobeIcon" class="text-success mr-05" />
            {{ data.account }}
          </span>
          <span class="mr-2 fs-14 d-flex align-items-center">
            <feather-icon icon="CalendarIcon" class="text-dark mr-05" />
            {{ parseDate(data.date) }}
          </span>
          <span class="fs-14 d-flex align-items-center">
            <feather-icon icon="ClockIcon" class="text-dark mr-05" />
            {{ parseDate(data.timestamp) }}
          </span>
        </div>
        <div class="scroller mt-2" style="max-height: 25rem;">
          <p
            class="text-justify"
            v-html="markSearchItem(data.content, search_item)"
          ></p>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BModal, VBModal } from "bootstrap-vue";
import helper from "@/helper/helper.js";

export default {
  name: "contentModal",
  components: {
    BModal,
  },
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    search_item: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  methods: {
    parseDate(date) {
      return helper.dateParser(date);
    },
    markSearchItem: function (content, search_item) {
      let kata = [];
      if (content.toLowerCase().includes(search_item)) {
        kata.push(search_item);
      }
      kata = [...new Set(kata)];
      kata.forEach(function (search_item) {
        search_item = search_item.replaceAll('"', "");
        search_item = search_item.trim();
        let reg_exp = new RegExp("(\\b" + search_item + "\\b)", "gi");
        content = content.replace(
          reg_exp,
          ` <mark data-markjs="true"> ${search_item}  </mark> `
        );
      });
      return content;
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>