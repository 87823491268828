<template>
  <div id="related-link-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-link-45deg"></i>
          <span style="margin-left: 5px"> Related Link </span>
        </b-card-title>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <b-button variant="outline-success" @click="exportData">
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter_data.key"
              placeholder="Masukkan kata kunci..."
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- dataTable -->
      <dataTable
        v-else
        :data="table_data"
        :viewDetail="viewDetail"
        :is_row_clicked="true"
        :is_pagination="false"
      />
      <paginationInfo
        :page="pagination.page"
        :items="pagination.items"
        :count="pagination.count"
        @update-value="paginationChanged"
      />
    </b-card>
    <!-- content modal component -->
    <contentModal :data="modal_data" :search_item="filter_data.key" />
  </div>
</template>
<script>
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import loader from "@/views/components/loader.vue";
import contentModal from "@/views/components/related-link/contentModal.vue";

export default {
  name: "RelatedLinkLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    paginationInfo,
    dataTable,
    loader,
    contentModal,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        key: "baca juga",
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "account",
            label: "nama akun",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "title",
            label: "judul",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "url",
            label: "url",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "date",
            label: "tanggal",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "timestamp",
            label: "timestamp",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      modal_data: null,
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    exportData() {
      const params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        text: this.filter_data.key,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      const url = `${process.env.VUE_APP_API_URL}scarper/related-link/export?${query}`;
      window.open(url);
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        text: this.filter_data.key,
        page: this.pagination.page,
        items: this.pagination.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scarper/related-link?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data?.scraper || [];
          this.pagination.count = res.data?.page_info[0]?.total || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    viewDetail(id) {
      let data = this.table_data.items.filter((el) => el._id == id);
      this.modal_data = data[0];
      this.$bvModal.show("modal-content");
    },
  },
};
</script>
